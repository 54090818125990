"use client";

import Script from "next/script";

export function MetaAnalytics() {
  // const pathname = usePathname();
  // const searchParams = useSearchParams();
  const META_ID = process.env.NEXT_PUBLIC_META_FACEBOOK_ID as string;
  // useEffect(() => {
  //   if (pathname) {
  //     pageview(pathname);
  //   }
  // }, [pathname, searchParams]);
  return (
    <>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=402455185604837&ev=PageView&noscript=1"
        />
      </noscript>
      <Script
        id="facebook-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${META_ID}');
fbq('track', 'PageView');`,
        }}
      />
    </>
  );
}
