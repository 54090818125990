"use client";
import { useRouter } from "next/navigation";

import { showNotification } from "@/util/userNotifications";

export function Auth({
  children,
  authToken,
}: {
  children: React.ReactNode;
  authToken: string | null;
}) {
  const router = useRouter();
  if (!authToken && typeof window !== "undefined") {
    showNotification({
      type: "caution",
      message: "ログインしてください。",
    });
    router.replace("/signup");
    return children;
  }
  return children;
}
