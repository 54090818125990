"use client";

import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  Operation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLError } from "graphql";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      // showNotification({
      //   type: "error",
      //   message: message,
      // });
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 10000,
    jitter: true,
  },
  attempts: {
    max: 5,
    // ネットワークエラーまたはサーバーエラーの場合のみ再試行
    retryIf: (error: any, operation: Operation) => {
      if (error.networkError) {
        // タイムアウトエラーの場合のみ再試行
        if (error.networkError.statusCode === 408) {
          return true;
        }
        // その他のネットワークエラーは再試行しない
        return false;
      }
      if (error.graphQLErrors) {
        return error.graphQLErrors.some(
          (err: GraphQLError) => err.extensions?.code === "SERVER_ERROR",
        );
      }
      return false;
    },
  },
});

export function makeClient(authToken: string | undefined = undefined) {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: authToken ? `Bearer ${authToken}` : "",
      },
    };
  });
  const httpLink = authLink.concat(
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
      fetchOptions: { cache: "no-store" },
    }),
  );

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, retryLink, authLink, httpLink]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    // typeof window === "undefined"
    //   ? ApolloLink.from([
    //       errorLink,
    //       new SSRMultipartLink({
    //         stripDefer: true,
    //       }),
    //       httpLink,
    //     ])
    //   : httpLink,
    // connectToDevTools: true,
  });
}
