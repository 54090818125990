import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/home/runner/work/care_link_worker/care_link_worker/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/node_modules/@mantine/dropzone/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/node_modules/@mantine/dates/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/node_modules/mantine-datatable/dist/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/src/components/functional/accessAnalysis/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/src/components/functional/accessAnalysis/MetaAnalytics.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/src/components/functional/auth/Auth.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/src/components/functional/auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/src/components/functional/provider/Provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/care_link_worker/care_link_worker/src/util/userNotifications/index.ts");
