"use client";
import { Provider, createStore } from "jotai";

import { authAtom } from "@/globalState";

export function AuthProvider({
  children,
  authToken,
}: {
  children: React.ReactNode;
  authToken: string | null;
}) {
  const authStore = createStore();
  authStore.set(authAtom, Boolean(authToken));
  return <Provider store={authStore}>{children}</Provider>;
}
