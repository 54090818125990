"use client";

import type { FC, ReactNode } from "react";

import { ApolloProvider } from "@apollo/client";
import { MantineProvider } from "@mantine/core";

import { makeClient } from "@/libs/apollo/provider";

import { Notifications } from "@/components/ui/parts";

import { customTheme } from "@/styles/theme";

type Props = {
  children: ReactNode;
  authToken: string | null;
};

export const Provider: FC<Props> = ({ children, authToken }) => {
  return (
    <ApolloProvider client={makeClient(authToken ? authToken : undefined)}>
      <MantineProvider theme={customTheme()}>
        {children}
        <Notifications />
      </MantineProvider>
    </ApolloProvider>
  );
};
