import { MantineThemeOverride, rem } from "@mantine/core";

export function customTheme(): MantineThemeOverride {
  return {
    headings: {
      sizes: {
        h1: { fontSize: rem(22) },
        h2: { fontSize: rem(18) },
        h3: { fontSize: rem(16) },
        h4: { fontSize: rem(16) },
        h5: { fontSize: rem(16) },
      },
    },
    colors: {
      line: [
        "#06C755",
        "#06C755",
        "#06C755",
        "#06C755",
        "#06C755",
        "#06C755",
        "#06C755",
        "#06C755",
        "#06C755",
        "#06C755",
      ],
    },
  };
}
